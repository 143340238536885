<script>
import post from '@/utils/axios/post'
import { listConvertToModel } from '@/models'
import CoursePackage from '@/models/cource_package/index'
import store from '@/store'
import { mapState } from 'vuex'
import CoursePackageComponent from '@/views/fun_template/course_package'
import request from '@/api/request'


export default {
  components: {
    CoursePackageComponent
  },
  props: {
    // 弹出进行编辑
    alertEdit: {
      type: Boolean,
      default: true,
    },
  },
  provide () {
    return {
      alertEdit: this.alertEdit,
    }
  },
  data () {
    return {
      keyword: '',
      // list: [],
      loading: false,
      more: false,
      page: 0,
      teachingType: 1,
    }
  },
  computed: {
    ...mapState({
      // courseId: state => state.course_package.id,
      coursePackageList: state => state.course_package.packageList,
    }),
    checkstatus () {
      const { $route } = this
      const { name, params } = $route
      // 草稿
      if (name === 'fun_template_draft') {
        return 5
      }
      // 我创建的
      if (name === 'fun_template_my' && params && params.type === 'created' ) {
        return 3
      }
      return 1
    },
    showCheckAndTime () {
      const { $route } = this
      const { name, params } = $route
      // 我创建的
      if (name === 'fun_template_my' && params && params.type === 'created' ) {
        return false
      }
      return true
    },
    /**
     * 是否可以删除 不可以: 我购买的
     * @param {Object} item
     * @return {boolean}
     */
    canDelete () {
      const { $route } = this
      const { name, params } = $route
      const { type } = params
      if (name === 'fun_template_my') {
        if (type === 'purchase') {
          return false
        }
      }
      return true
    }
  },
  mounted () {
    // console.log(this.checkstatus)
    this.init()
  },
  methods: {
    async init () {
      const {
        $route
      } = this
      console.log($route.params.type)
      this.clearList()
      if ($route.name === 'fun_template_my') {
        if ($route.params.type === 'created') {
          await this.getList()
        }
        if ($route.params.type === 'purchase') {
          await this.getPurchaseList()
        }
        return
      }
      await this.getList()


    },
    async getList () {
      if (this.loading) {
        return
      }
      this.loading = true
      const res = await request.request(
        '/resource/myCreateCource',
        {
          keyword: this.keyword,
          pn: ++ this.page,
          checkstatus: this.checkstatus,
          teachingType: this.teachingType,
        }
      )
      this.loading = false
      if (res.message === 'success') {
        const list = listConvertToModel({
          list: res.contents.page.result,
          model: CoursePackage
        })
        const newList = [
          ...this.coursePackageList,
          ...list
        ]
        store.commit(
          'setCoursePackageList',
          newList
        )
        if (list.length === 20) {
          this.more = true
        } else {
          this.more = false
        }
      }
    },
    async getPurchaseList () {
      if (this.loading) {
        return
      }
      this.loading = true
      const res = await request.request(
        '/resource/mygetCource',
        {
          keyword: this.keyword,
          pn: ++ this.page,
          // checkstatus: this.checkstatus,
        }
      )
      this.loading = false
      if (res.message === 'success') {
        const list = listConvertToModel({
          list: res.contents.page.result,
          model: CoursePackage
        })
        const newList = [
          ...this.coursePackageList,
          ...list
        ]
        store.commit(
          'setCoursePackageList',
          newList
        )
        if (list.length === 20) {
          this.more = true
        } else {
          this.more = false
        }
      }
    },
    async doSearch (e) {
      if (e.keyCode !== 13) {
        return
      }
      this.clearList()
      await this.getList()
    },
    clearList () {
      // this.$set(
      //   this,
      //   'list',
      //   []
      // )
      store.commit(
        'setCoursePackageList',
        []
      )
      this.page = 0
      this.more = false
    },
    touchCoursePackage (id) {
      console.log(id)
      if (id) {
        store.commit('setCoursePackageId', id)
        this.$modal.show('globalCoursePackageEdit')
      }
    },
    async loadMore () {
      await this.getList()
    },
    changeTeachingType (type) {
      this.teachingType = type
      this.clearList()
      this.getList()
    }
  }
}
</script>

<template>
<div
  v-loading="loading"
  class="course_package_list d-flex flex-column bbox_wrapper"
>
  <div
    class="search_group flex-shrink-0 d-flex align-center"
  >
    <div
      class="teaching_type_wrapper d-flex align-center"
    >
      <div
        class="tag"
        :class="{
          on: teachingType === 1
        }"
        @click="changeTeachingType(1)"
      >
        学校教学实践
      </div>
      <div
        class="tag"
        :class="{
          on: teachingType === 2
        }"
        @click="changeTeachingType(2)"
      >
        企业岗位应用
      </div>
    </div>
    <div class="search_box">
      <div class="icon"></div>
      <input
        type="text"
        class="search"
        placeholder="输入内容，Enter进行搜索"
        v-model="keyword"
        @keyup.enter="doSearch"
      />
    </div>
  </div>
  <div
    class="list_wrapper d-flex align-center flex-column"
  >
    <div
      class="list_group d-flex align-center justify-start flex-wrap"
    >
      <template
        v-for="(item, index) in coursePackageList"
      >
        <CoursePackageComponent
          :key="index"
          :course-package="item"
          :show-check-and-time="showCheckAndTime"
          :can-delete="canDelete"
        ></CoursePackageComponent>
        <!--<div-->
        <!--  :key="index"-->
        <!--  class="course_package d-flex flex-column"-->
        <!--  @click.stop="touchCoursePackage(item.id)"-->
        <!--&gt;-->
        <!--  <div-->
        <!--    class="title_group"-->
        <!--  >-->
        <!--    {{ item.title }}-->
        <!--  </div>-->
        <!--  <div-->
        <!--    class="pic_group"-->
        <!--  >-->
        <!--    <div-->
        <!--      v-if="item.webpic"-->
        <!--      :style="{-->
        <!--        backgroundImage: `url(${ $file_url }${ item.webpic })`-->
        <!--      }"-->
        <!--      class="pic"-->
        <!--    >-->
        <!--    </div>-->
        <!--    <div-->
        <!--      v-else-->
        <!--      class="text_pic un_sel d-flex align-center justify-center"-->
        <!--    >-->
        <!--      {{ item.title }}-->
        <!--    </div>-->
        <!--  </div>-->
        <!--</div>-->
      </template>
    </div>
    <div
      v-show="more"
      class="more_group d-flex justify-center flex-shrink-0"
    >
      <div
        class="more cp un_sel"
        @click.stop="loadMore"
      >
        加载更多
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.course_package_list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .search_group {
    width: 100%;
    .teaching_type_wrapper {
      .tag {
        cursor: pointer;
        font-size: 13px;
        margin-right: 10px;
        color: #333;
        &.on {
          color: $main_blue;
        }
      }
    }
    // 搜索
    .search_box {
      @include flex;
      @include bbox;
      width: 300px;
      height: 40px;
      background: rgba(243, 243, 243, 1);
      border-radius: 4px;
      padding: 0 10px;
      .icon {
        width: 14px;
        height: 14px;
        background: {
          image: url(~@/assets/images/common/search_icon.png);
          size: 100%;
        }
      }
      .search {
        @include placeholder(#999);
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 14px;

        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        padding-left: 10px;
        width: 200px;
      }
    }
  }
  .list_wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 20px 0;
    .list_group {
      width: 100%;
      height: 100%;
      overflow: auto;
      .course_package {
        margin: {
          right: 10px;
          bottom: 15px;
        }
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        .title_group {
          @include single_line_intercept;
          flex-shrink: 0;
          text-align: left;
          width: 100%;
          padding: {
            top: 10px;
            bottom: 15px;
            left: 20px;
            height: 20px;
          }
        }
        .pic_group {
          width: 100%;
          height: 100%;
          .text_pic {
            @include single_line_intercept;
            width: 100%;
            height: 100%;
            background-color: #cccccc;
            color: #fff;
            font-size: 30px;
            font-weight: bold;
            padding: 10px;
          }
          .pic {
            @include background;
            width: 100%;
            height: 100%;
            background-size: contain;
          }
        }
      }
    }
    .more_group {
      color: $main_blue;
      font-size: 14px;
      line-height: 30px;
      padding: 0 10px;
    }
  }
}
</style>
