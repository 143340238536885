var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "course_package_list d-flex flex-column bbox_wrapper",
    },
    [
      _c(
        "div",
        { staticClass: "search_group flex-shrink-0 d-flex align-center" },
        [
          _c(
            "div",
            { staticClass: "teaching_type_wrapper d-flex align-center" },
            [
              _c(
                "div",
                {
                  staticClass: "tag",
                  class: {
                    on: _vm.teachingType === 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeTeachingType(1)
                    },
                  },
                },
                [_vm._v("\n        学校教学实践\n      ")]
              ),
              _c(
                "div",
                {
                  staticClass: "tag",
                  class: {
                    on: _vm.teachingType === 2,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeTeachingType(2)
                    },
                  },
                },
                [_vm._v("\n        企业岗位应用\n      ")]
              ),
            ]
          ),
          _c("div", { staticClass: "search_box" }, [
            _c("div", { staticClass: "icon" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.keyword,
                  expression: "keyword",
                },
              ],
              staticClass: "search",
              attrs: { type: "text", placeholder: "输入内容，Enter进行搜索" },
              domProps: { value: _vm.keyword },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.doSearch.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.keyword = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "list_wrapper d-flex align-center flex-column" },
        [
          _c(
            "div",
            {
              staticClass:
                "list_group d-flex align-center justify-start flex-wrap",
            },
            [
              _vm._l(_vm.coursePackageList, function (item, index) {
                return [
                  _c("CoursePackageComponent", {
                    key: index,
                    attrs: {
                      "course-package": item,
                      "show-check-and-time": _vm.showCheckAndTime,
                      "can-delete": _vm.canDelete,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.more,
                  expression: "more",
                },
              ],
              staticClass: "more_group d-flex justify-center flex-shrink-0",
            },
            [
              _c(
                "div",
                {
                  staticClass: "more cp un_sel",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.loadMore.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        加载更多\n      ")]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }